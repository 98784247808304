import * as AjaxFunctions from './ajaxFunctions.js';
import md5 from 'crypto-js/md5.js';
import Base64 from 'crypto-js/enc-base64.js';

window.BVExtras = {};

BVExtras.isWeb = true;

BVExtras.sendEmail = async function(email, templateData){
   await AjaxFunctions.sendEmail(email, window.BV.idScript, templateData);
};

BVExtras.sendEmailWithImage = async function(email, image, additionalData){
   return await AjaxFunctions.sendEmailWithImage(email, image, window.BV.idScript, additionalData);
};

BVExtras.sendSMS = async function(phoneNumber, text){
   await AjaxFunctions.sendSMS(phoneNumber, text, window.BV.idScript);
};

BVExtras.sendSMSWithImage = async function(phoneNumber, text, image){
   await AjaxFunctions.sendSMSWithImage(phoneNumber, text, image, window.BV.idScript);
};

BVExtras.postVisitorInfo = async function(email, phoneNumber, other){
   await AjaxFunctions.postVisitorInfo(window.BV.idScript, email, phoneNumber, other);
};

BVExtras.sendVideo = async function(video, sendOptions){
   await AjaxFunctions.uploadVideo(window.BV.idScript, video, sendOptions);
};

BVExtras.postFormstack = async function(token, idForm, fields){
   await AjaxFunctions.postFormstack(token, idForm, fields);
};

BVExtras.submitSessionData = async function(data){
   await AjaxFunctions.submitSessionData(data);
};

BVExtras.quitApp = function() {
   window.location.reload();
};

BVExtras.computerShutdown = function() {
   window.location.reload();
};

BVExtras.updateLastUsed = async () => {
   await AjaxFunctions.updateLastUsed();
};

BVExtras.uploadImageWithFilename = async function(imageData, filename) {
   let uploadFileName = filename;
   if(!uploadFileName){
      uploadFileName = generateUploadFileName();
   }

   return await AjaxFunctions.uploadImageWithFilename(imageData, uploadFileName, window.BV.idScript);
};

BVExtras.uploadVideoWithFilename = async function(videoData, filename) {
   let uploadFileName = filename;
   if(!uploadFileName){
      uploadFileName = generateUploadFileName();
   }

   return await AjaxFunctions.uploadVideoWithFilename(videoData, uploadFileName, window.BV.idScript);
};

function generateUploadFileName(){
   let tmp = Base64.stringify( md5( new Date().toISOString()) );
   return tmp.replaceAll('+', 'p')
      .replaceAll('=', 'e')
      .replaceAll('/', 'fs')
      .replaceAll('\\', 'bs');

}

BVExtras.generateUploadFileName = () => generateUploadFileName();